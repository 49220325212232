let title = "Express1040"
const appYear = "2023"

const routes = [
	{
		path: "/",
		name: "Home",
		component: () => import("../views/Home"),
		meta: {
			title:
				title + " - Free Federal Tax Filing - Authorized IRS E-File Website",
			canonical: "https://www.express1040.com",
			metaTags: [
				{
					name: "description",
					content:
						"E-File your tax return directly to the IRS for free. Prepare federal and state income taxes online. " +
						appYear +
						" tax preparation software."
				},
				{
					name: "keywords",
					content:
						"free, tax, filing, online, return, preparation, e-file, income, taxes, efile, irs, prepare, federal, state, " +
						appYear +
						", software"
				},
				{
					name: "robots",
					content: "index, follow"
				}
			]
		}
	},
	{
		path: "/:catchAll(.*)*",
		name: "PageNotFound",
		component: () => import("../views/PageNotFound"),
		meta: {
			title: title + " - Requested Page Not Found on",
			metaTags: [
				{
					name: "description",
					content: "Requested Page Not Found on"
				}
			]
		}
	},

	// alphabetic pages
	{
		path: "/about",
		name: "About",
		component: () => import("../views/About"),
		meta: {
			title: "Information About " + title + " - Best Value for Your Money",
			canonical: "https://www.express1040.com/about",
			metaTags: [
				{
					name: "description",
					content:
						"Information about our company. Express1040 data for users. Find out why we support all the income tax, rebate, refund, deduction, and credit you need."
				},
				{
					name: "keywords",
					content:
						"tax support income deduction refund supporting info about company federal efile returns"
				},
				{
					name: "robots",
					content: "index, follow"
				}
			]
		}
	},
	{
		path: "/accessibility",
		name: "Accessibility",
		component: () => import("../views/Accessibility"),
		meta: {
			title: title + " - Accessibility",
			canonical: "https://www.express1040.com/accessibility",
			metaTags: [
				{
					name: "description",
					content:
						"Information about our company. Express1040 data for users. Find out why we support all the income tax, rebate, refund, deduction, and credit you need."
				},
				{
					name: "keywords",
					content:
						"tax support income deduction refund supporting info about company federal efile returns"
				},
				{
					name: "robots",
					content: "index, follow"
				}
			]
		}
	},
	{
		path: "/account-recovery",
		name: "AccountRecovery",
		component: () => import("../views/AccountRecovery"),
		meta: {
			title: "Sign In and Recover " + title + " Account",
			canonical: "https://www.express1040.com/account-recovery",
			metaTags: [
				{
					name: "description",
					content:
						"Need help accessing your account? We'll help you recover your account with links to restore your password and username."
				},
				{
					name: "keywords",
					content: "forgot,password,username,locked,out,account,restore,access"
				},
				{
					name: "robots",
					content: "index, follow"
				}
			]
		}
	},
	{
		path: "/audit",
		name: "Audit",
		component: () => import("../views/Audit.vue"),
		props: true,
		meta: {
			title: "Audit Defense: File with Protection and Peace of Mind",
			canonical: "https://www.express1040.com/audit",
			metaTags: [
				{
					name: "description",
					content:
						"Get up to $1 million in services to defend you if you’re audited, including an audit defense team, IRS and state support, tax debt relief, and more."
				},
				{
					name: "keywords",
					content:
						"audit, audit defense, free, tax, federal, filing, online, return, preparation, e-file, income, taxes, efile, irs, prepare, federal, state, " +
						appYear +
						", software"
				},
				{
					name: "robots",
					content: "index, follow"
				}
			]
		}
	},
	{
		path: "/blog",
		name: "Blog",
		component: () => import("../views/Blog"),
		meta: {
			title:
				title +
				" FREE Tax Filing, Online Return Preparation, E-File Income Taxes",
			canonical: "https://www.express1040.com/blog",
			metaTags: [
				{
					name: "description",
					content:
						"100% Free Tax Filing. Efile your tax return directly to the IRS. Prepare federal and state income taxes online."
				},
				{
					name: "keywords",
					content:
						"free, tax, filing, online, return, preparation, e-file, income, taxes, efile, irs, prepare, federal, state"
				},
				{
					name: "robots",
					content: "noindex, nofollow"
				}
			]
		}
	},
	{
		path: "/contact",
		name: "Contact",
		component: () => import("../views/Contact"),
		meta: {
			title:
				"Contact " +
				title +
				" with Questions and Comments - Website, Company, & Affiliate Questions",
			canonical: "https://www.express1040.com/contact",
			metaTags: [
				{
					name: "description",
					content:
						"We appreciate your feedback. We will respond as soon as possible. Please be advised that this response may be delayed by weekends and holidays."
				},
				{
					name: "keywords",
					content: "contact, us, express1040"
				},
				{
					name: "robots",
					content: "index, follow"
				}
			]
		}
	},
	{
		path: "/deadline",
		name: "Deadline",
		component: () => import("../views/Deadline"),
		meta: {
			title: "Important Tax Dates & Deadlines | " + title,
			canonical: "https://www.express1040.com/deadline",
			metaTags: [
				{
					name: "description",
					content:
						"Get the latest info on important tax dates here. Express1040 can help you file on time or file an extension. The best part, federal taxes are always free, and state taxes are $15 or less."
				},
				{
					name: "keywords",
					content: "deadline,due,date,filing,taxes,april"
				},
				{
					name: "robots",
					content: "index, follow"
				}
			]
		}
	},
	{
		path: "/deluxe",
		name: "Deluxe",
		component: () => import("../views/Deluxe"),
		meta: {
			title: title + " Best Tax Software - Deluxe Online Tax Program",
			canonical: "https://www.express1040.com/deluxe",
			metaTags: [
				{
					name: "description",
					content:
						"The best tax service at the lowest price. Prepare your taxes using Express1040 Deluxe online version. Low cost income and business tax filing. Get the best!"
				},
				{
					name: "keywords",
					content:
						"tax service do free income tax preparation online prepare federal return returns e-file e-filing irs refund efile preparation electronic filing"
				},
				{
					name: "robots",
					content: "index, follow"
				}
			]
		}
	},
	{
		path: "/email-removal",
		name: "EmailRemoval",
		component: () => import("../views/EmailRemoval"),
		meta: {
			title:
				"Unsubscribe from free tax e-filing information provided by " + title,
			canonical: "https://www.express1040.com/email-removal",
			metaTags: [
				{
					name: "description",
					content:
						"Report a wrong email address to unsubscribe from future mailings"
				},
				{
					name: "keywords",
					content: "unsubscribe,wrong,email"
				},
				{
					name: "robots",
					content: "index, follow"
				}
			]
		}
	},
	{
		path: "/email-security",
		name: "EmailSecurity",
		component: () => import("../views/EmailSecurity"),
		meta: {
			title: title + " - Email Security",
			canonical: "https://www.express1040.com/email-security",
			metaTags: [
				{
					name: "description",
					content:
						"Information about protecting yourself from phishing and fake email scams"
				},
				{
					name: "keywords",
					content: "tax support phishing fake email scam safety"
				},
				{
					name: "robots",
					content: "index, follow"
				}
			]
		}
	},
	{
		path: "/estimate",
		name: "Estimate",
		component: () => import("../views/Estimate"),
		meta: {
			title:
				title + " - Estimate your tax refund and file your federal taxes free.",
			canonical: "https://www.express1040.com/estimate",
			metaTags: [
				{
					name: "description",
					content:
						"Sign in and estimate your " +
						appYear +
						" tax refund or taxes owed. When you're ready, update your entries, and file your federal tax return for free."
				},
				{
					name: "keywords",
					content: "income, tax, calculator, estimator, irs, federal, state"
				},
				{
					name: "robots",
					content: "index, follow"
				}
			]
		}
	},
	{
		path: "/extension",
		name: "Extension",
		component: () => import("../views/Extension"),
		meta: {
			title:
				title +
				" Income Tax Extension, Federal Filing, E-File IRS Extension Online",
			canonical: "https://www.express1040.com/extension",
			metaTags: [
				{
					name: "description",
					content:
						"Free tax return extension filing. Easily file your state and federal tax extension forms online. Extend the deadline with fast IRS e-file."
				},
				{
					name: "keywords",
					content: "extension, income, tax, filing, e-file, irs, form, federal"
				},
				{
					name: "robots",
					content: "index, follow"
				}
			]
		}
	},
	{
		path: "/forms",
		name: "Forms",
		component: () => import("../views/Forms"),
		props: true,
		meta: {
			title: title + " Supported Federal Tax Forms",
			canonical: "https://www.express1040.com/forms",
			metaTags: [
				{
					name: "description",
					content:
						"Find the " +
						appYear +
						" federal tax forms you need. Official IRS income tax forms are printable and can be downloaded for FREE. Get the current year income tax forms today!"
				},
				{
					name: "keywords",
					content:
						"income tax, supported, forms, download, free federal, individual, tax schedule, irs, online preparation"
				},
				{
					name: "robots",
					content: "index, follow"
				}
			]
		}
	},
	{
		path: "/answers-search",
		name: "AnswersSearch",
		component: () => import("../views/AnswersSearch"),
		props: true,
		meta: {
			title: title + " - Questions and Answers",
			canonical: "https://www.express1040.com/answers-search",
			metaTags: [
				{
					name: "description",
					content:
						"Free customer support for Express1040 users. Ask tax questions and get quick answers online. We support income tax, rebate, refund, deduction questions and more!"
				},
				{
					name: "keywords",
					content:
						"tax, support, income, deduction, refund, supporting, federal, technical, returns"
				}
			]
		}
	},
	{
		path: "/giveaway",
		name: "Giveaway",
		component: () => import("../views/Giveaway"),
		meta: {
			title: title + " Giveaway - OFFICIAL RULES",
			canonical: "https://www.express1040.com/giveaway",
			metaTags: [
				{
					name: "description",
					content: "Rules for entering the cash giveaway on Express1040.com"
				},
				{
					name: "keywords",
					content: "giveaway,cash,rules,drawing"
				},
				{
					name: "robots",
					content: "index, follow"
				}
			]
		}
	},
	{
		path: "/group",
		name: "Group",
		component: () => import("../views/Group"),
		props: true,
		meta: {
			title:
				title +
				" FREE Tax Filing, Online Return Preparation, E-File Income Taxes",
			canonical: "https://www.express1040.com/group",
			metaTags: [
				{
					name: "description",
					content:
						"100% Free Tax Filing. Efile your tax return directly to the IRS. Prepare federal and state income taxes online. " +
						appYear +
						" tax preparation software."
				},
				{
					name: "keywords",
					content:
						"free, tax, filing, online, return, preparation, e-file, income, taxes, efile, irs, prepare, federal, state, " +
						appYear +
						", software"
				}
			]
		}
	},
	{
		path: "/group-low-income",
		name: "GroupLowIncome",
		component: () => import("../views/GroupLowIncome"),
		props: true,
		meta: {
			title:
				title +
				" FREE Tax Filing, Online Return Preparation, E-File Income Taxes",
			canonical: "https://www.express1040.com/group-low-income",
			metaTags: [
				{
					name: "description",
					content:
						"100% Free Tax Filing. Efile your tax return directly to the IRS. Prepare federal and state income taxes online. " +
						appYear +
						" tax preparation software."
				},
				{
					name: "keywords",
					content:
						"free, tax, filing, online, return, preparation, e-file, income, taxes, efile, irs, prepare, federal, state, " +
						appYear +
						", software"
				}
			]
		}
	},
	{
		path: "/seniors",
		name: "Seniors",
		component: () => import("../views/Seniors"),
		props: true,
		meta: {
			title:
				title +
				" FREE Tax Filing, Online Return Preparation, E-File Income Taxes",
			canonical: "https://www.express1040.com/seniors",
			metaTags: [
				{
					name: "description",
					content:
						"100% Free Tax Filing. Efile your tax return directly to the IRS. Prepare federal and state income taxes online. " +
						appYear +
						" tax preparation software."
				},
				{
					name: "keywords",
					content:
						"free, tax, filing, online, return, preparation, e-file, income, taxes, efile, irs, prepare, federal, state, " +
						appYear +
						", software"
				}
			]
		}
	},
	{
		path: "/answers",
		name: "Answers",
		component: () => import("../views/Answers"),
		meta: {
			title: title + " - Answers",
			canonical: "https://www.express1040.com/answers",
			metaTags: [
				{
					name: "description",
					content: "Answers to your questions"
				},
				{
					name: "keywords",
					content: "answers, questions, faq"
				},
				{
					name: "robots",
					content: "index, follow"
				}
			]
		}
	},
	{
		path: "/lending-tree",
		name: "Lendingtree",
		component: () => import("../views/Lendingtree"),
		meta: {
			title: title + " - Get your free credit score!",
			canonical: "https://www.express1040.com/lending-tree",
			metaTags: [
				{
					name: "description",
					content: "Get your free credit score!"
				},
				{
					name: "keywords",
					content: "income, tax, credit score, irs, federal, state"
				},
				{
					name: "robots",
					content: "index, follow"
				}
			]
		}
	},
	{
		path: "/prior-year",
		name: "PriorYear",
		component: () => import("../views/PriorYear"),
		meta: {
			title:
				"Prior Year Tax Return Software | File Previous Year Taxes with " +
				title,
			canonical: "https://www.express1040.com/prior-year",
			metaTags: [
				{
					name: "description",
					content:
						"Prepare old tax returns online. Federal filing is always free. State filing is $17.99. File back taxes for " +
						(appYear - 1) +
						", " +
						(appYear - 2) +
						", " +
						(appYear - 3) +
						", and other years."
				},
				{
					name: "keywords",
					content: "prior,year,tax,return,software,previous,taxes"
				},
				{
					name: "robots",
					content: "index, follow"
				}
			]
		}
	},
	{
		path: "/privacy",
		name: "Privacy",
		component: () => import("../views/Privacy"),
		meta: {
			title:
				title + " Privacy Policy Regarding Free Tax Preparation and E-File",
			canonical: "https://www.express1040.com/privacy",
			metaTags: [
				{
					name: "description",
					content:
						"Express1040 treats your financial and personally identifiable information as confidential. All information gathered is encrypted using secure socket layer technology (SSL)."
				},
				{
					name: "keywords",
					content: "privacy,statement,confidential,information"
				},
				{
					name: "robots",
					content: "noindex, nofollow"
				}
			]
		}
	},
	{
		path: "/pro",
		name: "Pro",
		component: () => import("../views/Pro"),
		meta: {
			title:
				title +
				" Pro - File your taxes online and get the help of a tax expert when needed.",
			canonical: "https://www.express1040.com/pro",
			metaTags: [
				{
					name: "description",
					content:
						"Start your tax return and enter your information. When you have a specific question, you'll be able to schedule a virtual meeting with a CPA or EA."
				},
				{
					name: "keywords",
					content: "tax,pro,expert,help,cpa,ea,assistance,questions"
				},
				{
					name: "robots",
					content: "index, follow"
				}
			]
		}
	},
	// {
	//   path: '/referral',
	//   name: 'Referral',
	//   component: () => import('../views/Referral'),
	//   meta: {
	//     title: title + ' Refer-A-Friend Email',
	//     canonical: 'https://www.express1040.com/referral',
	//     metaTags: [
	//       {
	//         name: 'description',
	//         content: 'Refer-A-Friend Email'
	//       },
	//       {
	//         name: 'keywords',
	//         content: 'Refer-A-Friend Email'
	//       },
	//       {
	//         name: 'robots',
	//         content: 'index, follow'
	//       }
	//     ]
	//   },
	// },
	{
		path: "/referral-thanks",
		name: "ReferralThanks",
		component: () => import("../views/ReferralThanks"),
		meta: {
			title: "Friend Referral Confirmation",
			canonical: "https://www.express1040.com/referral-thanks",
			metaTags: [
				{
					name: "description",
					content: "Tell A Friend Confirmation"
				},
				{
					name: "keywords",
					content:
						"federal return returns e-file e-filing irs refund efile electronic filing do free income tax preparation online prepare cash drawings giveaway referral"
				},
				{
					name: "robots",
					content: "index, follow"
				}
			]
		}
	},
	{
		path: "/reviews",
		name: "Reviews",
		component: () => import("../views/Reviews"),
		meta: {
			title: title + " Customer Reviews",
			canonical: "https://www.express1040.com/reviews",
			metaTags: [
				{
					name: "description",
					content: "Read thousands of 5 star reviews from satisfied customers."
				},
				{
					name: "keywords",
					content: "customer,reviews,consumer,ratings"
				},
				{
					name: "robots",
					content: "index, follow"
				}
			]
		}
	},
	{
		path: "/safe",
		name: "Safe",
		component: () => import("../views/Safe"),
		meta: {
			title:
				"Your information is secure with data encryption, account alerts, and two-factor authentication.",
			canonical: "https://www.express1040.com/safe",
			metaTags: [
				{
					name: "description",
					content:
						"Your information is safe. See how we protect your account with encryption, 2-factor authentication, security certificates, and account alerts."
				},
				{
					name: "keywords",
					content:
						"secure,safe,tax,information,encryption,privacy, security, protection, authorized"
				},
				{
					name: "robots",
					content: "index, follow"
				}
			]
		}
	},
	{
		path: "/secure",
		name: "Secure",
		component: () => import("../views/Secure"),
		meta: {
			title:
				"Review our security certificates and verify compliance with DigiCert, SecurityMetrics, and Truste.",
			canonical: "https://www.express1040.com/secure",
			metaTags: [
				{
					name: "description",
					content:
						"We keep your data safe by ensuring our privacy and security practices are aligned with industry standards."
				},
				{
					name: "keywords",
					content:
						"ecurity, certificate, safe, privacy, policy, accredited business, tax, filing"
				},
				{
					name: "robots",
					content: "index, follow"
				}
			]
		}
	},
	{
		path: "/share",
		name: "Share",
		component: () => import("../views/Share"),
		meta: {
			title: "Tell Your Friends About " + title,
			canonical: "https://www.express1040.com/share",
			metaTags: [
				{
					name: "description",
					content:
						"Tell your friends about the offer on Express1040 using this email tool. Everyone can prepare, print, and e-file their federal taxes for free."
				},
				{
					name: "keywords",
					content: "share,follow,email,friend,post,facebook,x,social"
				},
				{
					name: "robots",
					content: "index, follow"
				}
			]
		}
	},
	{
		path: "/sitemap",
		name: "Sitemap",
		component: () => import("../views/Sitemap"),
		meta: {
			title: title + " Sitemap & Helpful Links",
			canonical: "https://www.express1040.com/sitemap",
			metaTags: [
				{
					name: "description",
					content:
						"Express1040 sitemap can guide you on e-filing and preparation of your federal tax return online for free. Most people can complete their income tax return within 30 minutes."
				},
				{
					name: "keywords",
					content:
						"free efile preparation electronic filing income tax preparation online prepare federal return returns e-file e-filing irs refund"
				},
				{
					name: "robots",
					content: "index, follow"
				}
			]
		}
	},
	{
		path: "/software",
		name: "Software",
		component: () => import("../views/Software"),
		meta: {
			title:
				"Free Tax Software | Alternative to TurboTax, H&R Block, and TaxAct",
			canonical: "https://www.express1040.com/software",
			metaTags: [
				{
					name: "description",
					content:
						"Free Federal Tax Software | Alternative to TurboTax, H&R Block, and TaxAct"
				},
				{
					name: "keywords",
					content:
						"free,tax,software,pricing,service,details,support,compare,reviews"
				},
				{
					name: "robots",
					content: "index, follow"
				}
			]
		}
	},
	{
		path: "/state-privacy",
		name: "StatePrivacy",
		component: () => import("../views/StatePrivacy"),
		meta: {
			title: "State Privacy Policies on " + title,
			canonical: "https://www.express1040.com/state-privacy",
			metaTags: [
				{
					name: "description",
					content:
						"If you would like to make a state specific privacy request please follow these instructions."
				},
				{
					name: "keywords",
					content: "California,Consumer,Privacy,Act"
				},
				{
					name: "robots",
					content: "index, follow"
				}
			]
		}
	},
	{
		path: "/unsubscribe",
		name: "Unsubscribe",
		component: () => import("../views/Unsubscribe"),
		meta: {
			title:
				"Unsubscribe from Promotional and Tax Tip Information Provided by " +
				title,
			canonical: "https://www.express1040.com/unsubscribe",
			metaTags: [
				{
					name: "description",
					content:
						"Get the latest tax tips and promotional offers from Express1040. With Express1040.com, form 1040 is simple to use. Get your refund the fastest way possible."
				},
				{
					name: "keywords",
					content: "email,subscriptions,update,unsubscribe"
				}
			]
		}
	},
	{
		path: "/subscribe",
		name: "Subscribe",
		component: () => import("../views/Subscribe"),
		meta: {
			title:
				"Subscribe to Promotional and Tax Tip Information Provided by " + title,
			canonical: "https://www.express1040.com/subscribe",
			metaTags: [
				{
					name: "description",
					content:
						"Get the latest tax tips and promotional offers from Express1040. With Express1040.com, form 1040 is simple to use. Get your refund the fastest way possible."
				},
				{
					name: "keywords",
					content: "email,subscriptions,update,subscribe"
				}
			]
		}
	},
	{
		path: "/support",
		name: "Support",
		component: () => import("../views/Support"),
		meta: {
			title:
				title + " Customer Support for Federal Income Tax Return Questions",
			canonical: "https://www.express1040.com/support",
			metaTags: [
				{
					name: "description",
					content:
						"Free customer support for Express1040 users. Ask tax questions and get quick answers online. We support income tax, rebate, refund, deduction questions and more!"
				},
				{
					name: "keywords",
					content:
						"customer,support,contact,email,account,questions,answers,form"
				},
				{
					name: "robots",
					content: "index, follow"
				}
			]
		}
	},
	{
		path: "/survey",
		name: "Survey",
		component: () => import("../views/Survey"),
		meta: {
			title: title + " Survey - Please Tell Us How We Could Improve",
			canonical: "https://www.express1040.com/survey",
			metaTags: [
				{
					name: "description",
					content:
						"Please tell us what you think about our service. How could we improve our tax software?"
				},
				{
					name: "keywords",
					content: "survey,improve,questions"
				},
				{
					name: "robots",
					content: "index, follow"
				}
			]
		}
	},
	{
		path: "/terms",
		name: "Terms",
		component: () => import("../views/Terms"),
		meta: {
			title: title + " Website Terms of Use",
			canonical: "https://www.express1040.com/terms",
			metaTags: [
				{
					name: "description",
					content:
						"These Terms and Conditions of Use apply to the Express1040 website. By using the site, you agree to these terms of use."
				},
				{
					name: "keywords",
					content: "terms,conditions,use,service"
				},
				{
					name: "robots",
					content: "index, follow"
				}
			]
		}
	},
	{
		path: "/vulnerability-disclosure-policy",
		name: "VulnerabilityDisclosurePolicy",
		component: () => import("../views/VulnerabilityDisclosurePolicy"),
		props: true,
		meta: {
			title: title + " Vulnerability Disclosure Policy",
			canonical: "https://www.express1040.com/vulnerability-disclosure-policy",
			metaTags: [
				{
					name: "description",
					content: "Express1040 Vulnerability Disclosure Policy."
				},
				{
					name: "keywords",
					content:
						"vulnerability,disclosure,statement,confidential,information"
				},
				{
					name: "robots",
					content: "index, follow"
				}
			]
		}
	}
	// {
	//   path: '/version',
	//   name: 'Version',
	//   component: () => import('../views/Version'),
	//   meta: {
	//     title: '',
	//     canonical: 'https://www.express1040.com/version',
	//     metaTags: [
	//       {
	//         name: 'description',
	//         content: ''
	//       },
	//       {
	//         name: 'keywords',
	//         content: ''
	//       },
	//       {
	//         name: 'robots',
	//         content: 'index, follow'
	//       }
	//     ]
	//   },
	// },
]

export default routes
